




























































@import "src/assets/style/global"
$main-color: #128ff2
.title
  @include title
  .back
    float: left
    width: 50px
    height: 50px
    background-color: red
    @include title-button-left
.view
  padding: 20px
  margin-top: 20px
.tool-list
  margin-top: 20px
  div
    margin: 0 auto
    padding: 10px
    box-sizing: border-box
    display: flex
    align-items: center
    flex-direction: row
    justify-content: center
    list-style: none
    a
      border-radius: 8px
      @include default-a
      box-sizing: border-box
      padding: 25px 15px
      width: 100%
      max-width: 250px
      margin: 0 10px
      background-color: white
      @include base-shadow
      transition: color .3s, box-shadow .3s
      &:hover
        color: $main-color !important
